import React from "react"
import { COLORS } from "../styles/constants"
import { Link } from "@material-ui/core"

interface FooterLinkProps {
  title: string
  href: string
}
const FooterLink: React.FC<FooterLinkProps> = ({ title, href }) => {
  return (
    <div>
      <Link style={{ textDecoration: "none" }} href={href}>
        {title}
      </Link>
    </div>
  )
}

export default FooterLink

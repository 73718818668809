import React from "react"

import { COLORS } from "../styles/constants"
import { useTranslation } from "@3nvi/gatsby-theme-intl"
import { I18N_NAMESPACES } from "../i18next/i18n"
import FooterLink from "./footer-link"
import InternalFooterLink from "./footer-link copy"
import { Grid, Divider, Typography } from "@material-ui/core"
import {
  DONOTSKIP_EMAILS,
  DONOTSKIP_URLS,
  getMailToUrl,
} from "@arnaud-cortisse/donotskip-core"
import { ROUTES } from "../routes"

interface FooterProps {
  siteTitle: string
}
const Footer: React.FC<FooterProps> = ({ siteTitle = "" }) => {
  const { t: tCommon, i18n } = useTranslation(I18N_NAMESPACES.COMMON)

  return (
    <footer
      style={{
        padding: "1rem",
        backgroundColor: "#fff",
      }}
    >
      <Grid container justify="center" spacing={2} direction="column">
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          spacing={2}
          direction="column"
        >
          <Grid item>
            <Typography variant="subtitle2">{tCommon("Contact")}</Typography>
          </Grid>
          <Grid item>
            <FooterLink
              title={DONOTSKIP_EMAILS.DONOTSKIP_CONTACT}
              href={getMailToUrl(DONOTSKIP_EMAILS.DONOTSKIP_CONTACT)}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          direction="column"
          spacing={2}
        >
          <Grid item>
            <Typography variant="subtitle2">
              {tCommon("Other websites")}
            </Typography>
          </Grid>
          <Grid container item justify="center" spacing={2}>
            <Grid item>
              <InternalFooterLink
                title={tCommon("DoNotSkip Coaches")}
                to={ROUTES.COACHES}
              />
            </Grid>
            <Grid item>
              <FooterLink
                title={tCommon("DoNotSkip Programs")}
                href={DONOTSKIP_URLS.DONOTSKIP_PROGRAMS}
              />
            </Grid>
            <Grid item>
              <InternalFooterLink
                title={tCommon("DoNotSkip App")}
                to={ROUTES.MOBILE_APP}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid container item xs={12} justify="center" spacing={2}>
          <Grid item>
            <InternalFooterLink
              title={tCommon("Terms and Conditions")}
              to={ROUTES.TERMS_AND_CONDITIONS}
            />
          </Grid>
          <Grid item>
            <InternalFooterLink
              title={tCommon("Privacy Policy")}
              to={ROUTES.PRIVACY_POLICY}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} justify="center" spacing={2}>
          <Typography variant="caption">
            © {new Date().getFullYear()}
            {` `}
            {siteTitle}
          </Typography>
        </Grid>
      </Grid>
    </footer>
  )
}

export default Footer

import React from "react"
import { Link as MaterialLink } from "@material-ui/core"
import { Link } from "@3nvi/gatsby-theme-intl"

interface FooterLinkProps {
  title: string
  to: string
}

const InternalFooterLink: React.FC<FooterLinkProps> = ({ title, to }) => {
  return (
    <div>
      {/* @ts-ignore */}
      <MaterialLink component={Link} to={to}>
        {title}
      </MaterialLink>
    </div>
  )
}

export default InternalFooterLink

import React from "react"
import {
  AppBar,
  Toolbar,
  Typography,
  useTheme,
  Box,
  useScrollTrigger,
  Slide,
} from "@material-ui/core"
import icon from "../images/logo_96.png"
import LanguageSelector from "./LanguageSelector"
import { DONOTSKIP_URLS } from "@arnaud-cortisse/donotskip-core"
import { Link } from "@3nvi/gatsby-theme-intl"
import { ROUTES } from "../routes"

interface MyAppBarProps {
  siteTitle: string
}
const MyAppBar: React.FC<MyAppBarProps> = ({ siteTitle }) => {
  const theme = useTheme()
  const trigger = useScrollTrigger()

  return (
    <>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar position="fixed">
          <Toolbar>
            <Link
              to={ROUTES.HOME}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <Box display="flex" flexGrow={1} alignItems="center">
                <img
                  src={icon}
                  alt="App icon"
                  style={{ height: 48, width: 48, marginRight: 10 }}
                />

                <Typography variant="h6">{siteTitle}</Typography>
              </Box>
            </Link>
            <Box flexGrow={1} />
            <LanguageSelector />
          </Toolbar>
        </AppBar>
      </Slide>
      <Toolbar />
    </>
  )
}

export default MyAppBar

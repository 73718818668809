import React, { useCallback } from "react"
import Input from "@material-ui/core/Input"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import { useTranslation, usePageContext } from "@3nvi/gatsby-theme-intl"
import { Languages } from "@arnaud-cortisse/donotskip-core"
import { Link } from "gatsby"

interface LinkMenuItemProps {
  to: string
}
const LinkMenuItem: React.FC<LinkMenuItemProps> = ({
  to,
  children,
  ...rest
}) => (
  <MenuItem component={Link} to={to} {...rest}>
    {children}
  </MenuItem>
)
interface LanguageSelectProps {}
const LanguageSelector: React.FC<LanguageSelectProps> = () => {
  const { originalPath } = usePageContext()
  const { i18n } = useTranslation()

  return (
    // <FormControl color="primary">
    <Select
      color="primary"
      displayEmpty
      value={i18n.language}
      onChange={event => {
        const value = event.target.value as Languages.Ids
        // changeLanguage(value)
      }}
      input={<Input id="select-multiple" />}
      style={{ color: "#fff" }}
      IconComponent={() => <ArrowDropDownIcon />}
      renderValue={(value: unknown) => {
        return (
          <div
            style={{
              color: "#fff",
            }}
          >
            {value as string}
          </div>
        )
      }}
    >
      {Object.entries(Languages.supportedLanguages.allIds).map(
        ([_, languageId]) => {
          const language = Languages.supportedLanguages.byId[languageId]
          return (
            <LinkMenuItem
              key={language.id}
              to={`/${language.id}${originalPath}`}
            >
              {language.friendlyName}
            </LinkMenuItem>
          )
        }
      )}
    </Select>
    // </FormControl>
  )
}

export default LanguageSelector
